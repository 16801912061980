$(function(){
    $(document).ready(function() {
    	// Flash messages
    	$(".flash-message").each(function(index, el) {
    		var type = $(this).data('type');
    		var message = $(this).data('message');

    		console.log(type + " " + message);

    		if (type == 'warning') {
    			new PNotify({
					title: 'Upozornění',
					text: message,
					styling: 'bootstrap3'
				}); 	
    		} else if (type == 'info') {
    			new PNotify({
					title: 'Info',
					text: message,
					type: 'info',
					styling: 'bootstrap3'
				});
    		} else if (type == 'success') {
    			new PNotify({
					title: 'Povedlo se',
					text: message,
					type: 'success',
					styling: 'bootstrap3'
				});
    		} else if (type == 'error') {
    			new PNotify({
					title: 'Chyba',
					text: message,
					type: 'error',
					styling: 'bootstrap3'
				});
    		} else {
    			new PNotify({
					title: 'Info',
					text: message,
					type: 'info',
					styling: 'bootstrap3'
				});
    		}
    	});

    	// User Form
    	if ($("#frm-userForm-form").length > 0 || $("#frm-userEditForm-form").length > 0) {

    		function disableInputs($inputs) {
    			$inputs.each(function(index, el) {
    				$(this).prop('disabled', true);
    			});
    		}

    		function enableInputs($inputs) {
    			$inputs.each(function(index, el) {
    				$(this).prop('disabled', false);
    			});
    		}

    		if ($("#frm-userForm-form").length > 0) {
		    	function handleDisabled() {
		    		if ($("#frm-userForm-form-billingAdressIsSame").is(':checked')) {
		    			disableInputs($(".user-form-billingAdress"));
		    		} else {
		    			enableInputs($(".user-form-billingAdress"));
		    		}

		    		if ($("#frm-userForm-form-bankIsCzech").is(':checked')) {
		    			disableInputs($(".user-form-bankIsCzech"));
		    		} else {
		    			enableInputs($(".user-form-bankIsCzech"));
		    		}

		    		if ($("#frm-userForm-form-correspondentAdressIsSame").is(':checked')) {
		    			disableInputs($(".user-form-correspondentAdress"));
		    		} else {
		    			enableInputs($(".user-form-correspondentAdress"));
		    		}
		    	}
    		}

    		if ($("#frm-userEditForm-form").length > 0) {
    			function handleDisabled() {
		    		if ($("#frm-userEditForm-form-billingAdressIsSame").is(':checked')) {
		    			disableInputs($(".user-form-billingAdress"));
		    		} else {
		    			enableInputs($(".user-form-billingAdress"));
		    		}

		    		if ($("#frm-userEditForm-form-bankIsCzech").is(':checked')) {
		    			disableInputs($(".user-form-bankIsCzech"));
		    		} else {
		    			enableInputs($(".user-form-bankIsCzech"));
		    		}

		    		if ($("#frm-userEditForm-form-correspondentAdressIsSame").is(':checked')) {
		    			disableInputs($(".user-form-correspondentAdress"));
		    		} else {
		    			enableInputs($(".user-form-correspondentAdress"));
		    		}
		    	}	
    		}


	    	$("#frm-userForm-form-billingAdressIsSame").change(function(event) {
	    		handleDisabled();
	    	});

	    	$("#frm-userForm-form-bankIsCzech").change(function(event) {
	    		handleDisabled();
	    	});

	    	$("#frm-userForm-form-correspondentAdressIsSame").change(function(event) {
	    		handleDisabled();
	    	});

	    	$("#frm-userEditForm-form-billingAdressIsSame").change(function(event) {
	    		handleDisabled();
	    	});

	    	$("#frm-userEditForm-form-bankIsCzech").change(function(event) {
	    		handleDisabled();
	    	});

	    	$("#frm-userEditForm-form-correspondentAdressIsSame").change(function(event) {
	    		handleDisabled();
	    	});

	    	handleDisabled();
    	}

    	// Contract date range
    	if ($("input[name='dateFrom']").val() && $("input[name='dateUntil']").val()) {
    		$('#contract-date').daterangepicker({
			    "locale": {
			        "format": "DD-MM-YYYY",
			        "separator": " - ",
			        "applyLabel": "Použít",
			        "cancelLabel": "Zrušit",
			        "fromLabel": "Od",
			        "toLabel": "Do",
			        "customRangeLabel": "Vlastní",
			        "weekLabel": "T",
			        "daysOfWeek": [
			            "Ne",
			            "Po",
			            "Út",
			            "St",
			            "Čt",
			            "Pá",
			            "So"
			        ],
			        "monthNames": [
			            "Leden",
			            "Únor",
			            "Březen",
			            "Duben",
			            "Květen",
			            "Červen",
			            "Červenec",
			            "Srpen",
			            "Září",
			            "Říjen",
			            "Listopad",
			            "Prosinec"
			        ],
			        "firstDay": 1
			    },
			}, function(start, end, label) {
			  console.log("New date range selected: " + start.format("DD-MM-YYYY") + " to " + end.format("DD-MM-YYYY") + " (predefined range: ' + label + ')");
			});
			
			$('#contract-date').data('daterangepicker').setStartDate($("input[name='dateFrom']").val());
			$('#contract-date').data('daterangepicker').setEndDate($("input[name='dateUntil']").val());
		} else {
			$('#contract-date').daterangepicker({
				autoUpdateInput: false,
			    "locale": {
			        "format": "DD-MM-YYYY",
			        "separator": " - ",
			        "applyLabel": "Použít",
			        "cancelLabel": "Zrušit",
			        "fromLabel": "Od",
			        "toLabel": "Do",
			        "customRangeLabel": "Vlastní",
			        "weekLabel": "T",
			        "daysOfWeek": [
			            "Ne",
			            "Po",
			            "Út",
			            "St",
			            "Čt",
			            "Pá",
			            "So"
			        ],
			        "monthNames": [
			            "Leden",
			            "Únor",
			            "Březen",
			            "Duben",
			            "Květen",
			            "Červen",
			            "Červenec",
			            "Srpen",
			            "Září",
			            "Říjen",
			            "Listopad",
			            "Prosinec"
			        ],
			        "firstDay": 1
			    },
			}, function(start, end, label) {
			  console.log("New date range selected: " + start.format("DD-MM-YYYY") + " to " + end.format("DD-MM-YYYY") + " (predefined range: ' + label + ')");
			});
		}
		
		$('#contract-date').on('apply.daterangepicker', function(ev, picker) {
			$(this).val(picker.startDate.format("DD-MM-YYYY") + ' - ' + picker.endDate.format("DD-MM-YYYY"));
			$("input[name='dateFrom']").val(picker.startDate.format("DD-MM-YYYY"));
			$("input[name='dateUntil']").val(picker.endDate.format("DD-MM-YYYY"));
		});

		$('#contract-date').on('cancel.daterangepicker', function(ev, picker) {
			$(this).val('');
			$("input[name='dateFrom']").val('');
			$("input[name='dateUntil']").val('');
		});

		// Datatable
		$('.datatable').dataTable({
			language: {
			    "sEmptyTable":     "Tabulka neobsahuje žádná data",
			    "sInfo":           "Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů",
			    "sInfoEmpty":      "Zobrazuji 0 až 0 z 0 záznamů",
			    "sInfoFiltered":   "(filtrováno z celkem _MAX_ záznamů)",
			    "sInfoPostFix":    "",
			    "sInfoThousands":  " ",
			    "sLengthMenu":     "Zobraz záznamů _MENU_",
			    "sLoadingRecords": "Načítám...",
			    "sProcessing":     "Provádím...",
			    "sSearch":         "Hledat:",
			    "sZeroRecords":    "Žádné záznamy nebyly nalezeny",
			    "oPaginate": {
			        "sFirst":    "První",
			        "sLast":     "Poslední",
			        "sNext":     "Další",
			        "sPrevious": "Předchozí"
			    },
			    "oAria": {
			        "sSortAscending":  ": aktivujte pro řazení sloupce vzestupně",
			        "sSortDescending": ": aktivujte pro řazení sloupce sestupně"
			    }
			}
		});

		$('[data-toggle="tooltip"]').tooltip(); 
    });        
});